const pools = {
  cloudmeetdev: {
    clientId: '4r86placucc3b18c4kprqc1m6h',
    responseType: 'token',
    scope: 'openid+profile',
    redirectUri: 'http://localhost:3000/login',
    subdomain: 'cloudmeetdev',
  },
  cloudmeetqa: {
    clientId: '2mtg1mrcsrpapva0nj7d3p3rk8',
    responseType: 'token',
    scope: 'openid+profile',
    redirectUri: 'https://web.qa.cloudmeet.us/login',
    subdomain: 'cloudmeetqa',
  },
  cloudmeetstage: {
    clientId: '7l5me4hl12b8di84tvtka14ke3',
    responseType: 'token',
    scope: 'openid+profile',
    redirectUri: 'https://web.staging.cloudmeet.us/login',
    subdomain: 'cloudmeetstage',
  },
  cloudmeettest: {
    clientId: '4rbc4snt3mkb0rv8iucm27pqi1',
    responseType: 'token',
    scope: 'openid+profile',
    redirectUri: 'https://web.test.cloudmeet.us/login',
    subdomain: 'cloudmeettest',
  },
  cloudmeetprod: {
    clientId: '5670bkjou3e5v16fvgv0k20m19',
    responseType: 'token',
    scope: 'openid+profile',
    redirectUri: 'https://web.cloudmeet.us/login',
    subdomain: 'cloudmeetprod',
  },
}

type CognitoConfig = {
  clientId: string
  responseType: string
  scope: string
  redirectUri: string
  subdomain: string
}

const cognitoDomain = process.env.REACT_APP_COGNITO_DOMAIN || 'cloudmeetdev'
//@ts-ignore
const cognitoConfig: CognitoConfig = pools[cognitoDomain]

export const getCognitoUrl = (config: CognitoConfig) =>
  `https://${config.subdomain}.auth.us-west-2.amazoncognito.com/login?client_id=${config.clientId}&response_type=${config.responseType}&scope=${config.scope}&redirect_uri=${config.redirectUri}`

export default cognitoConfig
